import { ExternalResource } from '@tremaze/external-resource-types';
import { Injectable } from '@angular/core';
import {
  CRUDdDataSourceMock,
  ExternalResourceFixtures,
} from '@tremaze/fixtures';
import { ExternalResourceDataSource } from './external-resource-data-source';
import { Pagination } from '@tremaze/shared/models';
import { DataSourceMethodsPaginatedOptions } from '@tremaze/shared/util-http';
import { Observable, of } from 'rxjs';

@Injectable()
export class ExternalResourceDataSourceMock
  extends CRUDdDataSourceMock<ExternalResource>
  implements ExternalResourceDataSource
{
  readonly fixtures = new ExternalResourceFixtures();

  getPaginatedForInstitution(
    instId: string,
    options: DataSourceMethodsPaginatedOptions,
  ): Observable<Pagination<ExternalResource>> {
    return this.getPaginated(options);
  }

  createForInstitution(
    instId: string,
    entity: ExternalResource,
  ): Observable<ExternalResource> {
    return this.create(entity);
  }

  getOwnPaginated(
    options: DataSourceMethodsPaginatedOptions,
  ): Observable<Pagination<ExternalResource>> {
    return this.getPaginated(options);
  }

  hasOwnResources(): Observable<boolean> {
    return of(true);
  }
}

export const provideExternalResourceDataSourceMock = () => ({
  provide: ExternalResourceDataSource,
  useClass: ExternalResourceDataSourceMock,
});
